<style lang="scss">

.masonry-box {
  padding: 30px;
  margin-top: 50px;

  .cell-item {
    width: 100%;
    //margin-bottom: 20px;
    background: #ffffff;
    //border: 0px solid #F0F0F0;
    //border-radius: 0px;
    overflow: hidden;
    box-sizing: border-box;
    box-shadow: 2px 2px 6px rgba(3, 10, 24, 0.1);

    img {
      width: 100%;
      height: auto;
      min-height: 120px;
      display: block;
    }

    .item-body {
      font-size: 14px;
      color: #575150;
      line-height: 1.5;
      padding: 15px 20px 15px 20px;

      .item-desc {
        margin-top: 3px;
        position: relative;
        align-items: center;
        font-size: 12px;
        color: #aaa;
      }
    }
  }
}

@media only screen and (max-width: 981px) {
  .masonry-box {
    padding: 20px;
    .cell-item {
      //margin-bottom: 16px;
    }
  }
}

@media only screen and (max-width: 641px) {

  .masonry-box {
    padding: 20px;
    margin-top: 10px;

    .cell-item {
      //margin-bottom: 12px;
    }
  }
}
</style>
<template>

  <div class="container">
    <div class="masonry-box">
      <!--   <h3>Masonry: append endlessly</h3>-->
      <vue-masonry-wall :items="items" :options="options" @append="append">
        <template v-slot:default="{item}">
          <router-link :to=" '/'+category_path + '/' +category_id+ '/'+ item.id">
            <div class="cell-item">
              <img v-if="item.img" :src="item.img" alt="">
              <div class="item-body">
                {{ item.title }}
                <div class="item-desc">{{ item.desc }}</div>
              </div>
            </div>
          </router-link>
        </template>
      </vue-masonry-wall>
      <p v-show="loading" class="text-center">加载中...</p>
    </div>
  </div>
</template>
<script>
import VueMasonryWall from "vue-masonry-wall";
// import {Loading} from 'element-ui';

export default {
  name: "Masonry",
  props: {
    title: String,
    category_path: String,
    category_id: String,
    // category_type: String,
  },
  components: {VueMasonryWall},
  data() {
    return {
      items: [],
      // itemsShow: [],
      // category_id: 2,// 分类
      totalPage: 1,
      curPage: 0,
      options: {
        width: 300,
        padding: {
          default: 12,
          1: 8,
          2: 7,
          3: 8
        }
      },
      loading: false,

      originData: [
        {
          img: "https://image.watsons.com.cn//upload/8a316140.png?w=377&h=451&x-oss-process=image/resize,w_1080",
          avatar: "https://img.xiaohongshu.com/avatar/5b7d198a7e6e15000155f7c9.jpg@80w_80h_90q_1e_1c_1x.jpg",
          title: "最近浴室新宠，日系神仙好物了解一下～",
          id: "953"
        }]
    };
  },

  watch: {
    // category_id: function (val) {
    //   console.log("type:" + val);
    //   this.reset();
    // },
  },
  // filters: {
  //   categoryFilter: function (dataItems, customType) {
  //     let showData = [];
  //     for (var i in dataItems) {
  //       if (customType == 'all' || customType == dataItems[i].pid) {
  //         showData.push(dataItems[i]);
  //       }
  //     }
  //     console.log(showData);
  //     return showData;
  //   }
  // },
  beforeMount() {
    this.resizeHandler();
    window.addEventListener('resize', this.resizeHandler);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  created: function () {
    // console.log(this.$route.params);
  },
  computed: {
    // itemWidth() {
    //   return 133 * 0.5 * (document.documentElement.clientWidth / 375);
    // },
    // gutterWidth() {
    //   return 10 * 0.5 * (document.documentElement.clientWidth / 375);
    // }
  },
  methods: {
    reset() {
      // console.log("reset");
      this.items = [];
      // this.curPage = 0;
      // this.getNextPage();
    },
    resizeHandler() {
      if (document.documentElement.clientWidth < 640) {
        this.options.width = 200;
      } else if (document.documentElement.clientWidth < 990) {
        this.options.width = 300;
      } else {
        this.options.width = 300;
      }
      // console.log("resizeHandler", this.col);
    },
    getNextPage() {

      let that = this;
      let nextPage = this.curPage + 1;
      if (nextPage <= this.totalPage) {
        this.loading = true;
        this.$http.post(
            '/index/article/list',
            {
              category_id: this.category_id,
              limit: 10,
              page: nextPage
            })
            .then(function (response) {
              // console.log(response);
              that.loading = false;

              that.totalPage = response.data.last_page;
              that.curPage = response.data.current_page;

              for (let i = 0; i < response.data.data.length; i++) {
                let tmpItem = response.data.data[i];
                that.items.push(
                    {
                      img: tmpItem.image,
                      title: tmpItem.title,
                      desc: tmpItem.excerpt,
                      id: tmpItem.id,
                      pid: tmpItem.category_id,
                    }
                )
              }

            })
            .catch(function (error) {
              console.log(error);
            });
      }

    },
    append() {
      // console.log("append");
      this.getNextPage();
    }
  },
  mounted() {
    // this.getNextPage();
  }
};
</script>
